@import "../../../base/layout";
@import "../../../utilities/utilities";

.homecare_selection {
  padding-bottom: 20px;
  background:#fff;
  @media(min-width: map-get($grid-breakpoints, lg)) {
    padding-bottom: 0;
  }
  .cta_container {
    text-align: center;
    .cta_button__custom_plan {
      margin-top: 32px;
      border-color: var(--primary-color-orange);
      font-family: var(--font-bold);
    }
    .cta_button__separator {
      margin-top: 10px;
      margin-bottom: 10px;
      font-family: var(--font-bold);
    }

    @media(min-width: map-get($grid-breakpoints, lg)) {
      margin-top: -32px;
      .cta_button__custom_plan {
        font-size: 18px;
        padding-left: 50px;
        padding-right: 50px;
      }
      .cta_button__separator {
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .cta_button__package_plan {
        font-size: 18px;
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
}

.homecare_plan__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  padding-top: 48px;
  .homecare_plan__card_container {
    margin: 16px;
    margin-top: 24px;
    padding: 12px;
    border: 2px solid var(--theme-color-dark);
    border-radius: 12px;
    text-align: center;
    flex: 0 0 90%;
    max-width: 90%;
    padding-bottom: 20px;
    &:hover {
        border: 2px solid var(--primary-color-orange);
      }
    @media(min-width: map-get($grid-breakpoints, lg)) {
      margin: 0px;
      margin-top: 0px;
      flex: 0 0 48%;
    }
    display: grid;
    &[display-multiple-plans="false"] {
      min-width: 100%;
      padding-bottom: 24px;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:"a b"
                          "c b"
                          "c d"
                         ;
    }
    grid-template-areas:"a"
                        "b"
                        "c"
                        "d";
    .homecare_plan__card_title {
      grid-area: a;
    }
    .homecare_plan__card_image {
      margin-top: 12px;
      grid-area: b;
    }
    .homecare__feature_list {
      grid-area: c;
      margin-top: 12px;
      margin-bottom: 10px;
      @media(min-width: map-get($grid-breakpoints, lg)) {
        margin-top: 24px;
      }
    }
    .homecare_price_and_cta {
      align-self: flex-end;
      grid-area: d;
    }
    h2 {
        margin-bottom:64px;
        line-height: 1.3;
        display: none;
    }
    h4 {
        position: relative;
        display: inline-block;
        font-size:18px;
        margin-bottom: 16px;
        letter-spacing:0.3px;
        color:#000;
        &:before , &:after {
            display: inline-block;
            position: absolute;
            content:"";
            width:61px;
            height:1px;
            background:#979797;
            opacity:0.23;
            top:50%;

        }
        &:before {
            left:-8px;
            transform: translateY(-50%) translateX(-100%);
        }
        &:after {
            right:-8px;
            transform: translateY(-50%) translateX(100%);;
        }
    }
    ul {

        li {
            position: relative;
            font-size: 12px;
            line-height: 1.5;
            letter-spacing: 0.2px;
            font-family: var(--font-medium);
            color:#595959;
            padding-left:18px;
            text-align: left;
            & + li {
                margin-top:8px;
            }
            &:before {
                position: absolute;
                display:inline-block;
                text-align: center;
                font-family: "Icon";
                content:"\e903";
                font-size:5px;
                line-height: 12px;
                color:#fff;
                width:12px;
                height:12px;
                border-radius: 50%;
                background:var(--primary-color-green);
                vertical-align: middle;
                top:3px;
                left:0;

            }
        }
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
      h2 {
          margin-bottom:22px;
          line-height: 1.19;
          display: block;
      }
      h4 {
          display: none;
      }
      ul {
          li {
              line-height: 1.4;
              padding-left: 26px;
              margin-top: 0;
              font-size: 16px;
              &:before {
                  width:16px;
                  height:16px;
                  font-size:8px;
                  line-height: 16px;
              }
              & + li {
                  margin-top:12px;
              }
          }
      }
    }
    .homecare__pricing {
      margin: 16px auto;
      width: 80%;
      .pricing__price {
        font-family: var(--font-bold);
        font-size: 20px;
      }
      .pricing__hcp_quote {
        font-family: var(--font-bold);
        font-size: 18px;
      }
      .pricing__duration {
        font-size: 12px;
        color: var(--font-color-light-grey);
      }
      .pricing__duration__line__through {
        font-size: 12px;
        text-decoration: line-through;
        color: var(--font-color-light-grey);
        margin-bottom: 5px;
        opacity: 0.75;
      }
      .padding_top {
        padding-top: 15px;
      }
    }
    .homecare_plan__card_cta {
      .homecare_card__cta_button {
        width: 100%;
        @media(min-width: map-get($grid-breakpoints, lg)) {
          width: 80%;
        }
        padding: 10px;
      }
    }
  }
  @media(min-width: map-get($grid-breakpoints, lg)) {
    padding-top: 0px;
  }
}

.lead_form {
  @media(min-width: map-get($grid-breakpoints, lg)) {
    padding-bottom: 10px;
  }
  .lead_form__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .lead_form__inputs {
      padding-top: 40px;
      padding-bottom: 40px;
      line-height: 1.3;
      .lead_form__name {
        margin: 24px 0;
      }
      .lead_form__phone {
        margin: 24px 0;
      }
    }
    .lead_form__actions {
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
      margin-bottom: 40px;
      border-top: 1px solid var(--theme-color-lightgrey);
      @media(min-width: map-get($grid-breakpoints, lg)) {
        justify-content: space-around;
        padding: 16px 32px;
      }
      .lead_form__dismiss {
        font-size: 16px;
        font-weight: bold;
      }
      .lead_form__submit {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  h5 {
    margin-bottom: 10px;
  }
}
