@import "../../base/layout";

.city_selector{
	text-align: center;
	.header{
		padding: 16px;
		text-align: center;
		border-bottom: 1px solid var(--font-color-grey);
		h3{
			margin: 0;
			font-size: 32px;
			font-family: var(--font-bold);
			color: var(--font-color-dark2);
			@media (max-width:map-get($grid-breakpoints,md)) {
				font-size: 18px;
			}
		}
		h4{
			margin: 0;
			font-size: 24px;
			font-family: var(--font-semi-bold);
			color: var(--font-color-grey);
			@media (max-width:map-get($grid-breakpoints,md)) {
				font-size: 14px;
			}
		}
	}
	.content{
		padding: 16px;
		.title{
			font-family: var(--font-semi-bold);
			color: var(--font-color-dark3);
			padding-top: 24px;
			font-size: 18px;
			@media (max-width:map-get($grid-breakpoints,md)) {
				font-size: 14px;
				padding-top: 16px;
			}
		}
		.city_list{
			padding: 30px 0;
			.split2{
				display: none;
			}
			.split3{
				display: block;
			}
			.city{
				text-decoration: none;
				color: #3D3D3D;
				padding: 0;
				display: inline-block;
				margin: 20px 40px;
				font-size: 16px;
				@media (max-width:map-get($grid-breakpoints,md)) {
					margin: 10px 15px;
					font-size: 12px;
				}
				.icon{
					display: inline-block;
					width: 100px;
					height: 100px;
					@media (max-width:map-get($grid-breakpoints,md)) {
					width: 64px;
					height: 64px;
					}
				}
				.city_name{
					padding: 10px 0;
				}
				&:hover{
					font-weight: bold;
				}
			}
			&.count-4{
				.split2{
					display: none;
					@media (max-width:map-get($grid-breakpoints,lg)) {
						display: block !important;
					}
				}
				.split3{
					display: none;
				}
			}
		}
	}
}