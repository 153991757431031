@import "../../base/layout";

.city__div {
    color: #081c1b;
    text-align: center;
    // .city__heading {
    //     padding-top: 79px;
    //     text-align: center;
    //     @media (min-width: map-get($grid-breakpoints,lg)) {
    //     font-size: 36px;
    //     }
    //     @media (min-width: map-get($grid-breakpoints,md)) {
    //         font-size: 32px;
    //         }
    
    //     font-size: 24px;
    //     font-family: var(--font-bold);
    // }
    h4:not(#a) {
        margin-bottom:44px;
    }
    .city__cat {
        font-family: var(--font-bold);
        cursor: pointer;
        @media (min-width: map-get($grid-breakpoints,lg)) {
        margin-top: 35px;
        font-size: 24px;
        }
        @media (min-width: map-get($grid-breakpoints,md)) {
            font-size: 20px;
            }
        font-size: 16px;

        display: inline-flex;
        .city__cities {
            padding-right: 26px;
            padding-left: 26px;

            @media (min-width: map-get($grid-breakpoints,lg)) {
                padding-right: 64px;
                padding-left: 64px;
            }
        }
        .city__pipe{
            font-family: var(--font-medium);
        }
        .city__brands {
            padding-left: 40px;
        }
    }
    .city__enabled{
        text-decoration: underline;
    }
    .city__disabled{
        opacity: 0.5;
        font-family: var(--font-medium);
    }
    .cities_center{
        text-align: center !important;
        span{
            text-align:center !important;
            padding-left: 15px !important;
        }
    }
    .city__list {
        padding-top: 57px;
        text-align: left;
        
        @media (min-width: map-get($grid-breakpoints,lg)) {
        width: 1000px;
        }
        width: 100%;
        margin: 0px auto 0px auto;
        .city_click{
            a{
                text-decoration: none;
                color: #081c1b;
                cursor: pointer;
            }
        }
        span {
            white-space: nowrap;
            padding-left: 38px;
            text-align: left;
            font-family: var(--font-medium);
            @media (min-width: map-get($grid-breakpoints,lg)) {
            font-size: 20px;
            }
            @media (min-width: map-get($grid-breakpoints,md)) {
                font-size: 16px;
                }
            font-size: 14px;
            display: inline-block;
            padding-top: 15px;
            padding-right: 15px;
            padding-bottom: 15px;
            @media (min-width: map-get($grid-breakpoints,lg)) {
            width: calc(100% / 6);
            }
            width: calc(100% / 3);
            
        }
    }
    .heading{
        margin-top: 24px;
        justify-content: center;
        display: flex;
        font-size: 16px;
        line-height: 18px;
        font-family: var(--font-semi-bold);
    }
    
    .localities{

        align-items: center;
        text-align: center;
        
        .heading{
            h2{
                font-size: 14px;
                line-height: 17px;
                font-family: var(--font-semi-bold);
                text-align: center;
                color: #565F65;
                @media (min-width: map-get($grid-breakpoints,lg)) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
        .localities__list {
            padding-top: 60px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            
            @media (min-width: map-get($grid-breakpoints,lg)) {
                font-size: 20px;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
            }
            .city_click{
                cursor: pointer;
    
                a{
                    text-decoration: none;
                    color: #081c1b;
                }
            }
    
            span {
                flex: 0 0 50%;
                font-size: 14px;
                line-height: 20px;
                font-family: var(--font-semi-bold);
                text-align: center;
                align-items: center;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                @media (min-width: map-get($grid-breakpoints,lg)) {
                    flex: 0 0 20%;
                }
            }
        }
    }
    
}
