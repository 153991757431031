@import "../../../base/layout";

.image {

    img{
        width: 100%;
        height: auto;
        border-radius: 10px;
    }

}

.section_title {
    width: 100%;
    height: auto;
    font-family: var(--font-medium);
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: 0.4px;
    color: #000000;
    margin-bottom: 24px;
    text-align: center;


    @media (min-width: map-get($grid-breakpoints,md)) {
        font-size: 36px;
        width: 100%;
        text-align: center;
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        font-size: 36px;
        width: 100%;
        text-align: left;
    }
   
}

.features_list {
    ul {
        padding-top: 41px;

        li {
            position: relative;
            font-size: 14px;
            line-height: 1.71rem;
            letter-spacing: 0.2px;
            font-family: var(--font-medium);
            color:#595959;
            padding-left:30px;
            text-align: left;
            &:before {
                text-align: center;
                font-family: "Icon";
                position: absolute;
                display:inline-block;
                content:"\e903";
                width:20px;
                height:20px;
                border-radius: 50%;
                background:var(--primary-color-green);
                vertical-align: middle;
                top: 2px;
                left:0;
                color:#fff;
                font-size:10px;
                line-height: 20px;
                font-weight: bold;
                border: solid 1.2px #ffffff;

            }

            & + li {
                margin-top:24px;
            }
            @media (min-width: map-get($grid-breakpoints,md)) {
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.3px;
            }
        }
    }
}
