@import "../../../base/layout";


._row{  
    
        .items {
            padding-top: 24px;

            @media (min-width: map-get($grid-breakpoints,md)) {
                padding-top: 63px;
                
            }

            img{
                height: auto;
                width: 100%;
                border-radius: 8px;
                border: solid 1px #d8d6d6;
            }

            .text_content {
                @media (min-width: map-get($grid-breakpoints,md)) {
                    padding-top: 26px;
                    
                }
                .text_heading {
                    font-size: 16px;
                    font-family: var(--font-bold);
                    letter-spacing: 0.27px;
            
                    @media (min-width: map-get($grid-breakpoints,md)) {
                        font-size: 36px;
                        letter-spacing: 0.6px;
                        width: 100%;
                        line-height: 36px;
                    }
            
                    @media (min-width: map-get($grid-breakpoints,lg)) {
                        font-size: 36px;
                        letter-spacing: 0.6px;
                        width: 100%;
                        line-height: 36px;
                    }
                }
                .text_subcontent{
                    font-size: 14px;
                    line-height: 20px;
                    font-family: var(--font-medium);
                    color: #6a6a6a;
                    padding-top: 15px;
                    width: 100%;
        
                    @media (min-width: map-get($grid-breakpoints,md)) {
                        font-size: 20px;
                        line-height: 36px;
                        letter-spacing: 0;
                        padding-top: 17px;
                    
                    }
                }
            }
        }   
    }

    ._typograpy{
        width: 100%;
        text-align: center;
        h4 {
            font-size: 24px;
            font-family: var(--font-bold);
            line-height: 32px;
            letter-spacing: 0.4px;
    
            @media (min-width: map-get($grid-breakpoints,md)) {
                font-size: 36px;
                letter-spacing: 0.6px;
                width: 100%;
            }
        }
        h5 {
            font-size: 16px;
            line-height: 20px;
            font-family: var(--font-medium);
            color: #6a6a6a;
            padding-top: 15px;
            width: 100%;
    
            @media (min-width: map-get($grid-breakpoints,md)) {
                font-size: 20px;
                line-height: 36px;
                letter-spacing: 0;
                padding-top: 17px;
                
            }
        }
    }