@import '../../utilities/utilities';
@import '../../base/layout.scss';

._table {
    border-radius: var(--border-radius-lg);
    box-shadow: 0 2px 4px 0 #00000019;
    background: var(--theme-color-white);
    &.col_two ._col {
        width:50%;
        flex:0 0 50%;
        max-width: 50%;
    }
    &.col_three ._col {
        width:33.33%;
        flex:0 0 33.33%;
        max-width: 33.33%;
    }
    &.col_four ._col {
        width: 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media (max-width: map-get($grid-breakpoints,lg)) {
        > ._row {
            position: sticky;
            top:56px;
            background: #fff;
            border-bottom:2px solid #e9f5f9;
        }
    }
    ._row {
        @extend %flex;
        
        &:first-child {
            @media (min-width: map-get($grid-breakpoints,lg)) {
                position: sticky;
                top:85px;
                background: #fff;
                border-bottom:2px solid #e9f5f9;
            }
            ._col {
                padding-top: 20px;
                padding-bottom: 20px;
                @media (min-width: map-get($grid-breakpoints,lg)) {
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
                
            }
        }
        &:not(:first-child) {
            ._col:first-child {
                @media (min-width: map-get($grid-breakpoints,lg)) {
                    font-family: var(--font-medium);
                    text-align: left;
                }
            }
        }
    }
    ._row_label {
        background: #f4fafc;
        .col:not(:first-child) {
            display: none;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                display: block;
            }
            
        }
        ._col:first-child {
            width:100%;
            flex:0 0 100%;
            max-width: 100%;
        }
        @media (min-width: map-get($grid-breakpoints,lg)) {
           
            text-align: left;
            ._col:first-child {
                background: #e9f5f9;
                text-align: left;
                
            }
        }
    }
    ._col {
        text-align: center;
        padding:15px;
        line-height:1.3;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            padding: 40px;
        }
        & + ._col {
            border-left:solid 2px rgba(171,171,171,0.1);
        }
        h5 {
            font-size:12px;
            letter-spacing: 0.4px;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                font-size:24px;
            }
        }
        label {
            font-family: var(--font-bold);
        }
        span , label {
            font-size:12.80px;
            letter-spacing: 0.33px;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                font-size:18px;
            }
        }
        span[data-icon] {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width:12px;
            height:12px;
            border-radius: 50%;
            &:before {
                font-family: "Icon";
                font-size:5px;
                color:#fff;
                font-weight: bold;
            }
            &.check {
                &:before {
                    content:"\e903";
                }
                background:#00d65f;
                
            }
            &.cross {
                &:before {
                    content:'\e904';
                }
                background:#d0021b;
            }
            @media (min-width: map-get($grid-breakpoints,lg)) {
                width:24px;
                height:24px;
                &.check:before , &.cross:before  {
                    font-size: 8px;
                }
            }
        }
    }
   
    ._link{
        position: relative;
        font-size: 0.75rem;
        font-family: var(--font-bold);
        line-height: normal;
        letter-spacing: normal;
        color: var(--primary-color-orange);
        text-decoration:underline;
        cursor: pointer;
        &::after{
            position: absolute;
            right:-5px;
            top:50%;
            content: "\e901";
            font-family: 'Icon';
            font-size: 8px;
            text-decoration: none;
            color: inherit;
            transform: translate(100%,-50%);
            @media (min-width: map-get($grid-breakpoints , lg )) {
                font-size: 16px;
            } 
        }

        @media (min-width: map-get($grid-breakpoints , lg )) {
            font-size: 1.3rem;
        }  
    }
}