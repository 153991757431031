@import '../../utilities/_utilities.scss';
@import "../../base/layout";

.banner {
    padding:60px 0 20px 0;
    @media (min-width: map-get($grid-breakpoints,md)) {
        padding: 28px 0;
    }
    ._row {
        @extend %align-center;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            margin-bottom: 3.75rem;
        }
    }
    ._typhograpy {
        text-align: center;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            text-align: left;
        }
        ._h1 {
            line-height: 1.33;
            margin-top: 16px;
            margin-bottom:12px;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                font-size:42px;
            }
        }
        h5 {
            color: #6a6a6a;
            font-family: var(--font-regular);
            margin-bottom:24px;
            line-height: 1.36;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                margin-bottom:0;
                line-height: 1.5;
                font-size:24px;
                font-family: var(--font-medium);
                //padding-right: 110px;
                
            }
        }
        p {
            color: #6a6a6a;
            font-family: var(--font-regular);
            margin-bottom:24px;
            line-height: 1.36;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                margin-bottom:0;
                line-height: 1.5;
                font-size:24px;
                font-family: var(--font-medium);
                //padding-right: 110px;
                
            }
        }
    }
    ._graphic {
        display: none;
        margin-bottom: -2rem;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            display: block;
        }
        img {
            width:100%;
           
        }
    }
    ._banner_action {
        margin:24px 0 26px 0;
        a {
            padding-left:67px;
            padding-right: 67px;
            font-size:12px;
            font-family: var(--font-bold);
        }
        @media (min-width: map-get($grid-breakpoints,lg)) {
            margin-top:48px;
            margin-bottom:0;
            a {
                font-size: 20px;
                padding:18px 77px;
                border-radius:32px;
            }
        }      
    }
    ._hide_get_started {
        @media (max-width: map-get($grid-breakpoints,sm)) {  
            margin:0 0 0 0;
            height: 0;
           visibility: hidden;
        }
    }
    .location_breadcrumb{
        display: flex;
        flex-direction: column-reverse;
        margin-top: -2rem;
        @media (min-width: map-get($grid-breakpoints,md)) {
            margin-top: 0px;
            flex-direction: row;
            justify-content: space-between;
        }
        .breadcrumb{
            text-align: center;
            margin-top: 1rem;
            margin-bottom: -6rem;
            font-size: 12px;
            line-height: 14px;
            font-family: var(--font-regular);
            color: #565F65;
            @media (min-width: map-get($grid-breakpoints,md)) {
                font-size: 16px;
                line-height: 16px;
                text-align: left;
                margin: auto 0;
            }
            a{
                text-decoration: none;
                color: #565F65
            }
            a:hover{
                font-family: var(--font-bold);
            }
        }
        .location_selection{
            margin-bottom: 12px;
            width: max-content;
            cursor: pointer;
            @media (min-width: map-get($grid-breakpoints,md)) {
                margin-top: 0px;
                margin-bottom: 0px;
                width: unset;
            }
            display: flex;
            flex-direction: row;
            position: relative;
            &:before{
                font-family: "Icon";
                position: absolute;
                content: "";
                font-size: 1rem;
                color: #535353;
                opacity: 1;
                transform: translateY(-50%);
                font-weight: bold;
                margin-top: 13px;
                right: 0%;
                margin-right: -25px;
                @media (min-width: map-get($grid-breakpoints,md)) {
                    right: 0;
                    padding-right: 0px;
                    padding-left: 0px;
                    margin-right: -4px;
                }
                .location_img{
                    margin: auto 0;
                }
            }
            ._city{
                max-width:115px;
                @media (min-width: map-get($grid-breakpoints,md)) {
                    max-width:115px;
                    margin-right: 24px;
                }
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: initial;
                font-family: var(--font-semi-bold);
                font-size: 14px;
                line-height: 20px;
                padding-left: 5px;
                cursor: pointer;
                margin: auto 0;
            }
        }
        .triangle{
            position: absolute;
            border-bottom: 11px solid #3E4054;
            @media (min-width: map-get($grid-breakpoints,md)) {
                right: 0%;
                border-bottom: 11px solid white;
                left: unset;
                top: unset;
                margin-right: 30px;
                margin-top: 3px;
            }
            border-right: 11px solid transparent;
            border-left: 11px solid transparent;
            z-index: 2;
            display: inline-block;
            margin-top: 30px;
            margin-left: 40px;
            top: 0%;
            z-index: 2;
        }
        .or{
            display: flex;
            flex-direction: row;
            font-size: 14px;
            font-family: var(--font-regular);
          }
          .or:before, .or:after{
            content: "";
            flex: 1 1;
            border-bottom: 0.5px solid #565F65;
            margin: auto 20px;
            opacity: 0.2;
          }
          .or:before {
            margin-right: 16px
          }
          .or:after {
            margin-left: 16px
          }
        .location{
            position: absolute;
            background: white;
            top: 40px;
            text-align: center;
            height: auto;
            z-index: 1;
            min-width: 320px;
            max-width: 320px;
            padding-bottom: 20px; 
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            right: 0%;
            &:after{
                position: absolute;
                border-bottom: 11px solid #fff;
                border-right: 11px solid transparent;
                border-left: 11px solid transparent;
                margin-left: 1.5625rem;
                top: -10px;
                right: 48px;
                content: "";
            }
            .request{
                display: flex;
                justify-content: center;
                div{
                    margin: auto 0;
                }
                p{
                    width: 270px;
                    padding: 20px 10px 10px 10px;
                    font-size: 14px;
                    color: #3D3D3D;
                    font-family: var(--font-semi-bold);
                    text-align: initial;
                    line-height: 20px;
                    
                }
            }
            .detect_location{
                display: flex;
                justify-content: center;
                div{
                    margin: auto 0;
                }
                p{
                    padding: 25px 25px 25px 15px;
                    font-size: 14px;
                    color: #FDA000;
                    font-family: var(--font-semi-bold);
                    cursor: pointer;
                }
            }
        }
        .location_set{
            display: flex;
            position: absolute;
            justify-content: space-around;
            background: #3E4054;
            top:72px;
            text-align: center;
            height: auto;
            z-index: 1;
            min-width: 320px;
            max-width: 320px;
            border-radius: 4px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            @media (min-width: map-get($grid-breakpoints,md)) {
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                top: 40px;
                background: white;
                right: 0;
            }
            &:after{
                position: absolute;
                border-bottom: 11px solid #3E4054;
                border-right: 11px solid transparent;
                border-left: 11px solid transparent;
                margin-left: 1.5625rem;
                top: -10px;
                left: 11px;
                content: "";
                @media (min-width: map-get($grid-breakpoints,md)) {
                    border-bottom: 11px solid #fff;
                    right: 48px;
                    left: unset;
                }
            }
            .request{
                display: flex;
                justify-content: center;
                div{
                    margin: auto 0;
                }
                p{
                    padding: 20px 0px 20px 5px;
                    font-size: 14px;
                    color: white;
                    @media (min-width: map-get($grid-breakpoints,md)) {
                        color: #3D3D3D;
                    }
                    font-family: var(--font-semi-bold);
                    text-align: initial;
                    line-height: 20px;
                    
                }
            }
            .detect_location{
                display: flex;
                justify-content: center;
                div{
                    margin: auto 0;
                }
                p{
                    padding: 25px 25px 25px 15px;
                    font-size: 14px;
                    color: #FDA000;
                    font-family: #1C1C1C;
                    cursor: pointer;
                }
            }
            .change_option{
                padding: 20px 10px 20px 10px;
                line-height: 20px;
                font-family: var(--font-semi-bold);
                font-size: 14px;
                color: var(--primary-color-orange);
                cursor: pointer;
                @media (min-width: map-get($grid-breakpoints,md)) {
                    &:before{
                        font-family: "Icon";
                        position: absolute;
                        content: "";
                        font-size: 1rem;
                        color: var(--primary-color-orange); 
                        opacity: 1;
                        transform: rotate(-90deg);
                        font-weight: bold;
                        margin-top: 1.5px;
                        margin-left: 55px;
                        font-weight: 900;
                    }
                }
            }
        }
        .error_block
            {
                background-color: rgba(208, 2, 27, 0.1);
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
                .geo_error{
                    font-size: 12px;
                    line-height: 14px;
                    color: #D0021B;
                    text-align: initial;
                }
                p{
                    width: 250px;
                    line-height: 14px;
                    margin-left: 10px;
                    padding: 15px 0;
                    cursor: pointer;
                }
                .err_img{
                    margin: auto 0;
                }
            }
    }
    .item_toast{
        display: flex;
        position: fixed;
        background: #3E4054;
        z-index: 2;
        border-radius: 4px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        padding: 13px 0px 14px 40px;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            width: 15%;
            position: fixed;
            right: 20rem;
        }
        @media (max-width: map-get($grid-breakpoints,lg)) {
            margin: auto;
            position:fixed;
            left: 0;
            width:95%;
            box-shadow: 2px 0 4px 0 #00000019;
            bottom: 6rem;
            margin-left: 10px;
        }
        img{
            width: 16px;
            height: 16px; 
        }
        p{
            color: #ffffff;
            font-size: 12px;
            line-height: 14px;
            font-family: var(--font-semi-bold);
            padding-left: 14px;
        }
    }
}

.city_breadcrumb{
    padding: 20px 0px 0px 0px;
    min-height: 0px;
    @media (min-width: map-get($grid-breakpoints, md)) {
        padding: 20px 0px 0px 0px;
    }
}

.banner.instarepair{
    padding:40px 0 0 0;
    @media (min-width: map-get($grid-breakpoints, lg)) {
        // padding: 50px 102px 0 148px;
        padding: 50px 126px 0 148px;
    }

    ._typhograpy {
        text-align: center;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            text-align: left;
            padding-right: 0;
            // padding-left: 0;
        }
        // ._h1 {
    
        //     font-size: 32px;
        //     font-weight: bold;
        //     letter-spacing: 0.53px;
        //     line-height: 1.19;
        //     margin-top: 24px;
        //     @media (min-width: map-get($grid-breakpoints,lg)) {
        //         text-align: left;
        //         font-size: 42px;
        //         letter-spacing: 0.7px;
        //         margin-right: 0px;
        //         padding-right: 21px;
        //         margin-left: 0;
        //         font-weight: bold;
        //     }
        // }
        // h5 {
        //     color: #6a6a6a;
        //     font-size: 14px;
        //     font-family: var(--font-regular);
        //     line-height: 1.36;
        //     color: #6a6a6a;
        //     margin-left: 16px;
        //     margin-right: 2px;
        //     margin-bottom: 42px;
        //     text-align: center;
        //     @media (min-width: map-get($grid-breakpoints,lg)) {
        //         font-size: 20px;
        //         text-align: left;
        //         line-height: 1.5;
        //         padding-right: 125px;
        //         margin-right: 0;
        //         margin-left: 0;
        //         font-weight: 500;
        //         font-size:42px;
        //     }
        // }
    }
    ._graphic {
        @media (min-width: map-get($grid-breakpoints,lg)) {
            padding-left: 0;
            padding-right: 0;
    }
        img {
            width: 100%;
        //     @media (min-width: map-get($grid-breakpoints,lg)) {
        //         width: 640px;
        //         height: 475px;
        // }

    }
    figure > div {
        display: block !important;
    }
    }
}

// Protection-Plan Page
.banner.protection{
    padding:24px 0 0 0;
    @media (min-width: map-get($grid-breakpoints, lg)) {
        padding: 65px 220.3px 0 199px;
    }

    ._typhograpy {
        text-align: center;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            text-align: left;
        }
        // ._h1{
        //     padding-left: 23px;
        //     padding-right: 25px;
        //     font-size: 32px;
        //     font-weight: bold;
        //     letter-spacing: 0.53px;
        //     line-height: 1.19;
        //     margin-bottom: 24px;
        //     margin-left: 0px;
        //     margin-right: 0px;
        //     @media (min-width: map-get($grid-breakpoints,lg)) {
        //         text-align: left;
        //         padding-right: 0;
        //         font-size: 42px;
        //         letter-spacing: 0.7px;
        //         margin-bottom: 16px;
        //         margin-right: 0px;
        //         padding-right: 21px;
        //         padding-left: 16px;
        //         margin-left: 0;
        //         font-weight: bold;
        //     }
        // }
        // h5 {
        //     color: #6a6a6a;
        //     font-size: 14px;
        //     font-family: var(--font-regular);
        //     line-height: 1.36;
        //     color: #6a6a6a;
        //     padding-right: 17px;
        //     padding-left: 16px;
        //     margin-left: 0px;
        //     margin-right: 0px;
        //     margin-bottom: 82px;
        //     text-align: center;
        //     @media (min-width: map-get($grid-breakpoints,lg)) {
        //         font-size: 20px;
        //         text-align: left;
        //         padding-right: 2.35px;
        //         margin-right: 0;
        //         margin-left: 0;
        //         font-weight: 500;
        //         line-height: 1.6;
        //         letter-spacing: 0.33px;
        //         font-size:42px;
        //     }
        // }
    }

    ._graphic {
        @media (min-width: map-get($grid-breakpoints,lg)) {
            padding-left: 0;
            padding-right: 0;
    }
    // ._graphic {
    //     padding-left:33px;
    //     padding-right: 33px;
    //     @media (min-width: map-get($grid-breakpoints,lg)) {
    //         padding-left: 97.15px;
    //         padding-right: 0;
    // }
        img {
            width: 100%;
        //     width: 294px;
        //     height: 238px;
        //     @media (min-width: map-get($grid-breakpoints, md)) {
        //         width: 100%;
        //         height: 320px;
        //     }
        //     @media (min-width: map-get($grid-breakpoints,lg)) {
        //         width: 428.2px;
        //         height: 349px;
        // }

    }
    figure > div {
        display: block !important;
    }
    }
}
