@import '../../utilities/_utilities.scss';
@import "../../base/layout";

.feature_list {
    margin: 0 auto;
    text-align: center;

    .feature_item{

        & +.feature_item {
            @media (min-width: map-get($grid-breakpoints,md)),(max-width: map-get($grid-breakpoints,lg)) {
                margin-top: 0rem;
                padding-left: 36px;
                margin: 0 auto;
                margin-bottom: 80px;
                &.feature_item__double_row{
                    text-align: left;
                    padding-left: 10px;
                }
            }
        }
        @media (min-width: map-get($grid-breakpoints,md))  {
            &:nth-child(n+4) {
                margin-bottom:0;
            }
        }
        @media (max-width: map-get($grid-breakpoints,sm)) {
            &+.feature_item__singel_row {
                margin-top: 2.3rem;
            }
            &.feature_item__singel_row {
                display: flex;
                padding-left: 15px !important   ;
                margin-bottom: 0 !important;
            }
            &.feature_item__double_row {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px !important;
                padding: 0px 5px;
            }
        }
    }

    .feature_item__image {
        height: 63px;
        width: 63px;
        flex: 0 0 inherit;
        background-size: contain;
        background-repeat: no-repeat;
        &.feature_item__image__single_row{
            text-align: right;
            margin-right: 25px;
            // background-position: center center;
        }

        &.feature_item_image__double_row {
            height: 40px;
            width: 40px;
            flex: 0 0 40px;
            margin-bottom: 20px;
            background-position: left center;
            background-size: contain;
            @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,712px)) {
                margin-left:auto;
                margin-right:auto;
            }

        }
        &.culture_item_image__double_row {
            height: 222.81px;
            width: 300px;
            flex: 0 0 210px;
            margin: 0 auto;
            margin-bottom: 20px;
            background-position: left center;
            @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,712px)) {
                margin-left:auto;
                margin-right:auto;
            }

        }

    }
    .feature_item__text{
        text-align: left;
        line-height: 1.33;
        font-family: var(--font-medium);
        color: var(--font-color-light-grey);
        margin-top:auto;
        margin-bottom: auto;
        // font-size: 18px;

        .feature_item__title {
            line-height:1.63;
            font-family: var(--font-bold) ;
            color: var(--font-color-dark) ;
            // font-size: 14px;
            &.title__double_row {
                margin-bottom: 11px;
            }
            &.culture__double_row {
                margin-bottom: 11px;
                @media (max-width: map-get($grid-breakpoints, sm)) {
                    text-align: center;
                }
            }
            h3{
                margin-top: auto;
                margin-bottom: 11px;
            }
        }

    }
    .feature_item__nofeature{
        @media (max-width: map-get($grid-breakpoints, sm)) {
            display: flex;
        align-items: center;
        margin: 0px !important;
        }
        
    }

    .feature_item__subtext {
        line-height: 1.63;
        // font-size: 12px;
        color: var(--font-color-light-grey);
        &.culture__double_row {
            @media (max-width: map-get($grid-breakpoints, sm)) {
                text-align: center;
            }
        }
    }

    @media (min-width: map-get($grid-breakpoints, md)), (max-width: map-get($grid-breakpoints, lg)) {

        display: flex;
        justify-content: space-around;
        & > div {
            display: block;
        }

        .feature_item__subtext {
            line-height: 1.56;
            // font-size: 18px !important;
        }
        .feature_item__title {
            // font-size: 24px !important;
            font-family: var(--font-bold);
            color: var(--font-color-dark);
            line-height: normal;
        }

        .feature_item__text {
            text-align: center;
            // font-size: 20px;
            line-height: 1.4;
            color: var(--font-color-dark);
                        .line_break {
                white-space: pre-wrap;
            }
            &.item_text__double_row {
                text-align: left;
            }
            @media (max-width: map-get($grid-breakpoints, md)) {
                text-align: left !important;
                width: auto;
                max-width: 227px;
                
            }
        }
        .feature_item__nofeature{
        
        }
        .feature_item__image {
            &.feature_item__image__single_row{
                margin: 0 auto;
                @media (max-width: map-get($grid-breakpoints, md)) {
                    margin:0 ;
                    margin-right: 32px;      
                }
                }
            &.feature_item_image__double_row {
                height: 75px;
                width: 75px;
                background-position: center center;
                background-size: contain;
                }
                    }
    }
    @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
        .feature_item{
            // &:nth-child(n+3) {
            //     margin-top: 100px;
            // }
        }
    }

    &.grey {
        .feature_item__subtext{
            font-family: var(--font-medium);
            color: #656565;
            margin-top: 8px;
            @media (min-width: map-get($grid-breakpoints, md)), (max-width: map-get($grid-breakpoints, lg)) {
                margin-top: 20px;
            }
        }
    }
    &.grey__neutral {
        @media (min-width: map-get($grid-breakpoints, md)), (max-width: map-get($grid-breakpoints, lg)) {
            .feature_item__text{
                margin: 16px auto 0 auto;
                &.no_margin_double_row{
                    margin: 0;
                    width: 100%;
                }
            }
            .feature_item__nofeature{
        
            }

            .feature_item__subtext{
                color: var(--font-color-netural);
                // font-size: 16px;
                width: 300px;
                p{
                    margin-top: 10px
                }
                @media (max-width: map-get($grid-breakpoints, md)) {
                    p{
                        margin-top: 0px !important;
                    }
                }
            }
            // .feature_item__title{
            //     // font-size: 18px !important;
            // }
        }
        @media (max-width: map-get($grid-breakpoints, md)) {
            .feature_item__text{
                margin: auto;
                width: 227px;
            }    
        }
    }
    @media (min-width: 712px) and (max-width: map-get($grid-breakpoints, lg))  {
        justify-content: center;
        .feature_item__text {
            text-align: center;
        }
        .feature_item__image{
            //argin: 0 auto;
            &.feature_item__image__single_row{
                margin: 0 auto;
            }
        }
        .feature_item__nofeature{
        
        }
    }
}

.samsung__tab {
    @media (min-width: 576px) and (max-width: 720px){
            max-width: 720px ;
    }
}

.banner_title:not(#a) {
        h4{
        padding-left: 9px;
        padding-right: 19px;
        // text-align: left;
        text-align: center;
    }
}

@media (min-width: map-get($grid-breakpoints, md)), (max-width: map-get($grid-breakpoints, lg)) {
    .banner_title:not(#a) {
        h4{
            text-align: center;
        }
    }
}

.feature_desc {
    white-space: pre-wrap;
    // font-size: 12px;
    font-family: var(--font-regular);
    letter-spacing:0.15px;
    color:#575f65;
    line-height: 1.5;
    @media (min-width: map-get($grid-breakpoints, md)) {
        font-size:16px;
        letter-spacing:0.2px;
        line-height:1.63;
    }
    &.culture__desc {
        @media (max-width: map-get($grid-breakpoints, sm)) {
            text-align: center;
        }
    }
}

.font__dark {
    color: --var(--font-color-dark) !important;
}

.no__bottom_padding {
    @media (max-width: map-get($grid-breakpoints, md)) {
        h4 {
            margin-bottom: 0 !important;
        }
    }
}

.feature_list {
  align-items: baseline;
    &.space__between {
        justify-content: space-around;
    }
}

.subtitle__padding:not(#a) {
    h4 {
        margin-bottom: 24px !important;
    }
     p{
        text-align: center !important;
        font-size: 12px;
        line-height: 1.43;
        margin-bottom: 25px;
        @media (min-width: map-get($grid-breakpoints, md)), (max-width: map-get($grid-breakpoints, lg)) {
            font-size: 18px;
            line-height: 1.63;
            margin-bottom: 58px;
        }
    }
}

.paragraph_height {
    line-height: 1.43 !important;
    margin-top: 7px;
    font-size: 10px !important;
    @media (min-width: map-get($grid-breakpoints, md)), (max-width: map-get($grid-breakpoints, lg)) {
        font-size: 28px !important;
        margin-top: 40px;
        width: 300px;
        font-weight: 500;
    }
}

.feature_list {
    &.precautinary__measure {
        @media (min-width: map-get($grid-breakpoints, md)), (max-width: map-get($grid-breakpoints, lg)) {
            .feature_item__image {
                width: 180px;
                height: 180px;
            }

            .feature_item__text{
                .feature_item__title {
                    // font-size: 18px !important;
                    line-height: 1.43;
                }
            }
            .feature_item__subtext {
                // font-size: 14px !important;
                color: #595959;
                .line_break{
                    //no properties
                }
                .sub_text_width {
                    width: 234px;
                    height: 40px;
                    margin:  0 auto;
                }
            }
        }
    }
}



.main_subltitle {
    text-align: center;
    line-height: 1.43;
    color: #454343;
    font-size: 14px;
    margin-bottom: 16px;
    margin-bottom: 32px;
    @media (min-width: map-get($grid-breakpoints, md)), (max-width: map-get($grid-breakpoints, lg)) {
        line-height: 1.56;
        letter-spacing: 0.3px;
        font-size: 18px;
        margin-bottom: 48px;
        margin-left: auto;
        margin-right: auto;
    }
    &.career_text {
        text-align: left;
        @media (min-width: map-get($grid-breakpoints, md)), (max-width: map-get($grid-breakpoints, lg)) {
        font-size: 16px;
        color: #595959;
        line-height: 28px;
        }
    }
}


.feature_list {
    &.what_is_instarepair {
        @media (min-width: map-get($grid-breakpoints, md)), (max-width: map-get($grid-breakpoints, lg)) {
            .feature_item__image{
                width: 100px;
                height: 100px;
            }
            .feature_item__text {
                // font-size: 18px;
                line-height: 1.33;
                color: #050505;
            }
        }
    }
}

.round_circle {
    width: 80px;
    height: 80px;
    margin: 0 0 0.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 80px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px var(--light-grey);
    background-color: #ffffff;
    border-radius: 50%;
    &.large__circle_image {
        margin:0 auto;
        margin-bottom:20px;
        .feature_item__image__single_row {
            width: 48px;
            height: 48px;
            flex: 0 0 48px;
            margin: 0 auto;
        }
    }

    &.small__circle_image {
        // margin-right: 44px;
        margin:0 auto;
        .feature_item__image__single_row {
            width: 40px;
            height: 40px;
            flex: 0 0 40px;
            margin: 0 auto;
        }
    }
    &.amc__circle_image { // for this class there is a different css for desktop.
        margin-right: 32px;
        width: 80px;
        height: 80px;
        .feature_item__image__single_row {
            width: 48px !important;
            height: 48px !important;
            flex: 0 0 48px;
            margin: 0 auto;
        }
    }
    &.tiny__circle_image{
        width: 10rem;
        height: 10px;
        box-shadow: none;
        div{
            width: 35px !important;
            height: 35px !important;
        }
    }

    @media (min-width: map-get($grid-breakpoints, md)) and (min-width: map-get($grid-breakpoints,lg )) {
        width: 200px;
        height: 200px;
        flex: 0 0 200px;
        margin-bottom:25px;
        &.large__circle_image {
            .feature_item__image__single_row {
                width: 100px;
                height: 100px;
                flex: 0 0 100px;
            }
        }

        &.small__circle_image {
            margin: 0 auto;
            width: 120px;
            height: 120px;
            .feature_item__image__single_row {
                width: 80px;
                height: 80px;
                flex: 0 0 80px;
            }
        }
        &.amc__circle_image {
            margin: 0 auto;
            width: 120px;
            height: 120px;
            .feature_item__image__single_row {
                width: 72px !important;
                height: 72px !important;
                flex: 0 0 72px
            }
        }
    };
    @media (min-width: map-get($grid-breakpoints, md)) {
        &.small__circle_image {
            margin: 0 auto;
        }
    }

    @media (min-width: 712px) and (max-width: map-get($grid-breakpoints, lg))  {
        &.amc__circle_image{
            margin: 0 auto;
        }
    }
}


.feature_list {
    &.how_to_enjoy_ac {
        @media (min-width: map-get($grid-breakpoints, md)) , (max-width: map-get($grid-breakpoints, lg)) {
            .feature_item__image {
                width: 120px;
                height: 120px;
            }
        }
    }
}
.text_fix{
    .feature_item__text{
        margin-top: 1rem;
        margin-bottom: auto;
    }
    h4{
        margin-bottom: 24px !important;
    }
}

.culture__heading_mb:not(#a) {
    h4 {
        margin-bottom: 2.5rem;
    }
}
